import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Box, Text, Title } from "../../components/Core";

import { device } from "../../utils";

import imgShape from "../../assets/image/svg/contact-shape.svg";
import ContactForm from "../../components/ContactForm";
import { Link } from "gatsby";

const FormContainer = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: #f7f7fb;
  width: 100%;
`;

const BoxStyled = styled(Box)`
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 49%;
    content: "";
    width: 100%;
    background: ${({ theme }) => theme.colors.babyBlue};
    z-index: 1;
  }
`;

const Shape = styled(Box)`
  position: absolute;
  width: 150%;
  bottom: 48%;
  left: -5%;

  @media ${device.lg} {
    bottom: 47%;
  }
`;

const Contact = () => {
  return (
    <>
      <BoxStyled
        bg="babyBlue"
        pt={[0, null, null, 4]}
        pb={6}
        className="position-relative"
      >
        <Shape>
          <img src={imgShape} alt="" className="w-100 img-fluid" />
        </Shape>
        <Container
          css={`
            position: relative;
            z-index: 10;
          `}
        >
          <Row className="justify-content-center">
            <Col
              lg="9"
              xl="8"
              data-aos="fade-up"
              data-aos-duration="750"
              data-aos-once="true"
              data-aos-delay="500"
            >
              <FormContainer>
                <div className="mb-5 text-center">
                  <Title className="mb-2">Ready to talk?</Title>
                  <Text>
                    Complete the form below or{" "}
                    <Link to="/contact">contact me directly</Link>
                  </Text>
                </div>
                <ContactForm formName="contact-landing" />
              </FormContainer>
            </Col>
          </Row>
        </Container>
      </BoxStyled>
    </>
  );
};

export default Contact;

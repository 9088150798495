import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text, Button } from "../../components/Core";
import { device } from "../../utils";

import iconLayout from "../../assets/image/png/icon-layout.png";
import iconLayers from "../../assets/image/png/icon-layers.png";
import iconResponsive from "../../assets/image/png/icon-responsive.png";

const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ServicesSection = () => (
  <SectionStyled>
    <div
      data-aos="fade-up"
      data-aos-offset="-200"
      data-aos-anchor-placement="top-center"
      data-aos-duration="800"
      data-aos-once="true"
    >
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7" xl="6">
            <div className="text-center pb-5">
              <Title>Services</Title>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg="4" className="mb-5 mb-lg-4 text-center">
            <FeatureCard title="Executive Assistance" iconImage={iconLayout}>
              I can help you with general every-day admin tasks as well as
              executive assistance including inbox and calendar management,
              meeting coordination and travel arrangements.
            </FeatureCard>
            <Button to="/services" bg="secondary" size="small" mt={3}>
              Learn more
            </Button>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4 text-center">
            <FeatureCard title="Business Management" iconImage={iconLayers}>
              I can support you in the day to day running of your business
              including operations management, client management and project
              management.
            </FeatureCard>
            <Button to="/services" bg="secondary" size="small" mt={3}>
              Learn more
            </Button>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4 text-center">
            <FeatureCard title="Marketing" iconImage={iconResponsive}>
              I can assist you with marketing your business including web
              experience management, email campaigns and event coordination.
            </FeatureCard>
            <Button to="/services" bg="secondary" size="small" mt={3}>
              Learn more
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  </SectionStyled>
);

export default ServicesSection;

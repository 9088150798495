import React from "react";
import Hero from "../sections/landing/Hero";
import { graphql } from "gatsby";
import ServicesSection from "../sections/landing/ServicesSection";
import VirtualAdvantage from "../sections/landing/VirtualAdvantage";
import Testimonials from "../sections/landing/Testimonials";
import Contact from "../sections/landing/Contact";
import PageWrapper from "../components/PageWrapper";

const LandingPage = ({ data }) => {
  return (
    <PageWrapper headerDark footerDark={false}>
      <Hero />
      <ServicesSection />
      <VirtualAdvantage />
      <Testimonials data={data} />
      <Contact />
    </PageWrapper>
  );
};

export default LandingPage;

export const query = graphql`
  query {
    page: mdx(frontmatter: { id: { eq: "testimonials" } }) {
      body
    }
  }
`;

import React from "react";
import styled from "styled-components";
import { Container, Row } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";

import { device } from "../../utils";
import heroImg from "../../assets/image/jpeg/homepage-6.jpg";

const SectionStyled = styled(Section)`
  background: url(${heroImg}) no-repeat;
  background-size: cover;
  background-position: bottom left;

  @media ${device.lg} {
    background-position: center;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled className="position-relative">        
        <Container>
          <Row className="justify-content-center align-items-center position-relative px-3">
            <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
              <div
                data-aos="fade-left"
                data-aos-delay="400"
                data-aos-duration="1000"
              >
                <Title variant="hero" color="light">
                  Virtual Assistant Services
                </Title>
              </div>
              <div
                data-aos="fade-left"
                data-aos-delay="500"
                data-aos-duration="1000"
              >
                <Text mb={4} color="light" opacity={0.85}>
                  Online business support for start-ups, small business owners
                  and executives
                </Text>
              </div>
              <div
                className="d-flex flex-column align-items-start pt-3"
                data-aos="fade-left"
                data-aos-delay="200"
                data-aos-duration="1000"
              >
                <Button mb={2} to="/contact">
                  Get in touch
                </Button>
              </div>
            </Box>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;

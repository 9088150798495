import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Title, Section, Box, Text } from "../../components/Core";

const Testimonials = ({ data }) => {
  console.log("hi data", data);
  return (
    <Section bg="babyBlue" className="position-relative">
      <Container>
        <Text>
          <MDXRenderer>{data.page.body}</MDXRenderer>
        </Text>
      </Container>
    </Section>
  );
};

export default Testimonials;

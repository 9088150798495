import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgL1FeatureCurve from "../../assets/image/svg/l1-curve-feature.svg";

const SectionStyled = styled(Section)`
  padding-top: 90px;
`;

const FaqCard = styled(Box)`
  padding-left: 55px;
  position: relative;
  &::before {
    content: "\f061";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    min-width: 30px;
    min-height: 30px;
    max-height: 30px;
    font-size: 15px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.light};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 500px;
    position: absolute;
    left: 0;
  }
`;

const FaqTitle = styled(Title)`
  color: ${({ theme }) => theme.colors.light};
  font-size: 24px;
  font-weight: 900;
  letter-spacing: -0.75px;
  margin-bottom: 24px;
  line-height: 2rem;
`;

const ShapeBottmRight = styled(Box)`
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`;

const VirtualAdvantage = () => (
  <Section bg="secondary" className="position-relative">
    <ShapeBottmRight>
      <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
    </ShapeBottmRight>
    <Container>
      <div
        data-aos="fade-right"
        data-aos-offset="-300"
        data-aos-anchor-placement="top-center"
        data-aos-duration="800"
        data-aos-once="true"
      >
        <Row className="justify-content-center pb-5">
          <Col md="8" lg="9">
            <div className=" text-center">
              <Title color="light">The Virtual Advantage</Title>
            </div>
          </Col>
        </Row>
        <Row>
          {`Scalability
Flexibility 
Hourly pay
No additional employee related on-costs 
Working with experts
Access to experts from other fields`
            .split("\n")
            .map((item) => (
              <Col lg="6" className="mb-5">
                <FaqCard>
                  <FaqTitle>{item}</FaqTitle>
                </FaqCard>
              </Col>
            ))}
        </Row>
      </div>
    </Container>
  </Section>
);

export default VirtualAdvantage;

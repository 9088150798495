import React, { useState } from "react";
import { navigate } from "gatsby-link";
import styled from "styled-components";
import { Button, Box, Input } from "./Core";

const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.error};
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = ({ formName }) => {
  const [state, setState] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.target;

    const body = encode({
      "form-name": form.getAttribute("name"),
      ...state,
    });

    setErrorMessage(null);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body,
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => {
        setErrorMessage(
          "Sorry, something went wrong! Please try again or directly email me at christine@christinecolman.com.au"
        );
        console.error(error);
      });
  };

  return (
    <form
      method="post"
      netlify-honeypot="bot-field"
      data-netlify="true"
      name={formName}
      onSubmit={handleSubmit}
      action="/thanks/"
    >
      {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
      <input type="hidden" name="form-name" value={formName} />
      <p hidden>
        <label>
          Don’t fill this out:{" "}
          <input name="bot-field" onChange={handleChange} />
        </label>
      </p>
      <Box mb={3}>
        <Input
          type="text"
          name="name"
          label="Your name"
          placeholder="Your name"
          required
          onChange={handleChange}
        />
      </Box>
      <Box mb={3}>
        <Input
          type="email"
          name="email"
          label="Email address"
          placeholder="Email address"
          onChange={handleChange}
          required
        />
      </Box>
      <Box mb={3}>
        <Input
          type="tel"
          name="phone"
          label="Phone number"
          placeholder="Phone number"
          onChange={handleChange}
        />
      </Box>
      <Box mb={4} className="position-relative">
        <Input
          as="textarea"
          name="message"
          label="Message"
          placeholder="How can I assist you?"
          onChange={handleChange}
        />
      </Box>
      {errorMessage && (
        <Box mb={4} className="position-relative">
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </Box>
      )}
      <Button width="100%" type="submit" borderRadius={10}>
        Send
      </Button>
    </form>
  );
};

export default ContactForm;
